import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { Bind } from "../../decorators/bind.decorator";
import { MasterDefinitionTagsService } from "../../services/master.definitions/master.definition.tags.service";
import { MasterDefTag } from "../../pojo/MasterDefTag";
import { TypeManagerDecorator } from "../../main/type.map.service";
import TagWrapperI = MasterDefTag.TagWrapperI;
import { SearchCriteriaContext } from "../../services/search/search-criteria/search.criteria.context";
import MasterDefI = MasterDefTag.MasterDefI;

@TypeManagerDecorator("s25-edit-master-definition-tag")
@Component({
    selector: "s25-edit-master-definition-tag",
    template: `
        @if (isInit) {
            <div>
                <div class="tagRow">
                    <label class="ngBold" for="tag-name">Tag Name:</label>
                    <input
                        [(ngModel)]="tagDetails.tagName"
                        id="tag-name"
                        class="c-input"
                        name="tag-name"
                        type="text"
                        minlength="2"
                        maxlength="40"
                        required
                    />
                    @if (itemId) {
                        <s25-security-link [itemType]="'system_tags'" [itemId]="itemId"></s25-security-link>
                    }
                </div>

                <div class="tagRow">
                    <label class="ngBold" for="tag-comment">Description:</label>
                    <textarea
                        [(ngModel)]="tagDetails.comment"
                        rows="2"
                        cols="60"
                        class="cn-form__control"
                        id="tag-comment"
                    ></textarea>
                </div>

                @for (mdType of groupedMembersByMdType | keyvalue; track mdType) {
                    <h5 class="ngBold">{{ mdType.value.title }}</h5>
                    @for (md of mdType.value.items; track md; let j = $index) {
                        <div>
                            <s25-md-tag-item-collapse
                                [(masterDef)]="groupedMembersByMdType[mdType.key].items[j]"
                            ></s25-md-tag-item-collapse>
                        </div>
                    }
                }
            </div>
        }
    `,
    styles: `
        ::ng-deep .s25-multiselect-popup-container {
            max-width: 50vw;
        }
        h5 {
            display: flex;
            justify-content: center;
        }
        .tagRow {
            display: flex;
            flex-wrap: wrap;
            gap: 1em;
            align-items: center;
            margin: 0.5em 0;
        }

        .tagRow > label:first-child {
            min-width: 10em;
            margin: 0;
        }

        .tagRow > input {
            min-width: 15em;
        }
    `,
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.Emulated,
})
export class S25EditMasterDefinitionTagComponent implements OnInit {
    @Input() itemId: number;

    groupedMembersByMdType: GroupedMembers = {};
    tagDetails: { tagName: string; comment: string };

    isInit = false;
    errorMsg = "";

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    async ngOnInit() {
        await this.getTagData();
        this.isInit = true;
        this.cd.detectChanges();
    }

    async getTagData() {
        this.errorMsg = "";
        const tag = await MasterDefinitionTagsService.getTag(this.itemId);
        this.tagDetails = { tagName: tag.tag_name, comment: tag.comment };
        this.groupTagsByMdType(tag?.members);
    }

    groupTagsByMdType(members: MasterDefI[]) {
        members?.forEach((member) => {
            const searchCriteria = SearchCriteriaContext.Context[member.searchCriteriaName];

            if (searchCriteria) {
                member.multiSelectModel = {
                    showResult: true,
                    showMatching: false,
                    preFetchItems: false,
                    hasSelectAll: true,
                };
                member.displayName = MasterDefinitionTagsService.getDisplayName(
                    member.object_type,
                    searchCriteria.title,
                );

                if (this.groupedMembersByMdType[member.md_type]) {
                    this.groupedMembersByMdType[member.md_type].items?.push(member);
                } else {
                    this.groupedMembersByMdType[member.md_type] = {
                        title: searchCriteria.title,
                        items: [member],
                    };
                }
            }
        });
        this.sortGroupedMembers();
    }

    sortGroupedMembers() {
        Object.entries(this.groupedMembersByMdType).forEach(([key, value]) => {
            value.items.sort((a, b) => {
                return a.displayName.localeCompare(b.displayName);
            });
        });
    }

    validate() {
        this.errorMsg = "";
        if (!this.tagDetails.tagName || this.tagDetails.tagName.length < 2) {
            this.errorMsg = "A name longer than two characters is required";
            return false;
        }

        return true;
    }

    flattenMembers(groupedMembers: GroupedMembers): MasterDefI[] {
        let members: MasterDefI[] = [];
        Object.entries(groupedMembers).forEach(([key, value]) => {
            members.push(
                ...value.items.map((item) => {
                    return {
                        md_name: item.md_name,
                        changed: item.changed,
                        active: item.active ? 1 : 0,
                        addedItems: item.multiSelectModel.addedItems,
                        removedItems: item.multiSelectModel.removedItems,
                    } as MasterDefI;
                }),
            );
        });
        return members;
    }

    @Bind
    async save() {
        if (!this.validate()) return Promise.reject(this.errorMsg);
        this.errorMsg = "";

        const payload: TagWrapperI = {
            tag_id: this.itemId || undefined,
            tag_name: this.tagDetails.tagName,
            comment: this.tagDetails.comment,
            members: this.flattenMembers(this.groupedMembersByMdType),
        };

        return await MasterDefinitionTagsService.updateTag(this.itemId, payload);
    }
}

interface GroupedMembers {
    [key: string]: { title: string; items: MasterDefI[] };
}
